<template lang="">
  <div>
    <b-row class="match-height">
      <b-col cols="12" xl="12" lg="12" md="12">
        {{ userById }}
        <user-info-card :user="userById" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import UserInfoCard from './UserViewInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    UserInfoCard,
  },
  props: ['id'],
  computed: {
    userById() {
      console.log(this.id)
      return this.$store.getters.userById(Number(this.id))
    },
  },
}
</script>
<style lang=""></style>
