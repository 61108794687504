<template lang="">
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :text="user.username"
            variant="light"
            size="100px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ user.firstName }} {{ user.lastName }}
              </h4>
              <span class="card-text">{{ user.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              {{ user.role.name }}
            </div>
            <div>
              {{ user.role.description }}
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Benutzername</span>
            </th>
            <td class="pb-50">
              {{ user.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Erstellt</span>
            </th>
            <td class="pb-50">
              {{ user.created_at }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td
              v-if="user.confirmed"
              class="pb-50 text-capitalize"
            >
              Aktiv
            </td>
            <td
              v-else
              class="pb-50 text-capitalize"
            >
              Inaktiv
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Geblockt</span>
            </th>
            <td
              v-if="user.role.blocked"
              class="pb-50 text-capitalize"
            >
              Ja
            </td>
            <td
              v-else
              class="pb-50 text-capitalize"
            >
              Nein
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BCard, BButton, BAvatar, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style lang=""></style>
